<template>
  <div class="subscriptions-checkout-page">
    <div>
      <GoBackBtn class="m-0" :url="{ name: 'Subscriptions' }">Back</GoBackBtn>

      <div class="payment">
        <h2>{{ title }}</h2>

        <AppStripeElement :mode="stripeMode" :subscription_id="subscription_id" />
      </div>
    </div>
    <div v-show="!isEdit">
      <div class="col-12">
        <h2>ORDER DETAILS</h2>
      </div>
      <div class="col-12">
        <div class="plan">
          <div class="title">{{ subscription.name }}</div>
          <div class="price">
            ${{ subscription.price }}
            <span>/month</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSubscriptions } from '@/shared/api/Subscription';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppStripeElement from '@/components/Partial/Subscriptions/StripeElement.vue';

export default {
  name: 'SubscriptionCheckout',

  components: {
    GoBackBtn,
    AppStripeElement,
  },

  mixins: [],

  data() {
    return {
      subscription_id: Number(this.$route.params?.id),
      subscription: {
        id: this.$route.params?.id,
        name: '',
        price: 0,
      },
      loading: false,
      isEdit: this.$route.query?.edit,
    };
  },

  computed: {
    title() {
      return this.isEdit ? 'EDIT PAYMENT METHOD' : 'PAY WITH CREDIT CARD';
    },
    stripeMode() {
      return this.isEdit ? 'edit' : 'create';
    },
  },

  methods: {
    async fetchSubscriptions() {
      this.loading = true;

      const { data } = await getSubscriptions();

      this.subscription = data.find((a) => a.id === this.subscription_id);

      this.loading = false;
    },
  },

  async mounted() {
    await this.fetchSubscriptions();
  },
};
</script>

<style lang="scss" scoped>
.subscriptions-checkout-page {
  display: flex;

  min-width: 1100px;
  max-width: 1285px;
  margin: 0 auto;
  height: 100%;

  > div:nth-child(1) {
    border-right: 1px solid #CECECE;
    padding-right: 30px;
  }

  > div:nth-child(2) {
    padding-top: 204px;
    padding-left: 30px;
  }

  > div {
    width: 50%;
    padding-top: 140px;

  }

  .price {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: #282828;

    span {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.2px;
      color: #28282899;
    }
  }

  .plan {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-top: 40px;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 34px;
      letter-spacing: 0.2px;
      color: #282828;
    }
  }

  .payment {
    margin-top: 40px;
  }
}

</style>
