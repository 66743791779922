<template>
  <div class="mt-5">
    <div>
      <label>Card Holder Name</label>
      <input id="card-holder-name" type="text" v-model="name" class="form-control mb-2">
      <label :class="errorMessages.name ? 'd-block' : 'd-none'" class="text-danger">Name is required</label>
    </div>

    <div>
      <label>Card Details</label>
      <div id="card-element"></div>
      <label :class="errorMessages.card ? 'd-block' : 'd-none'" class="text-danger">{{ addPaymentStatusError }}</label>
    </div>

    <AppInputCheckbox v-model="checked" class="mt-3" label="Securely save this card for next purchase"/>

    <AppBtn :loading="loading" text @click="onPayClick" class="confirm">{{ buttonTitle }}</AppBtn>

    <!-- Success Modal -->
    <SuccessModal
      v-model="successModalShow"
      @confirm="onModalClose"
      :withDescription="false"
    >
      <template v-slot:title>{{ successModalText }}</template>
      <template v-slot:description></template>
    </SuccessModal>
  </div>
</template>

<script>
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import {
  getStripeClientSecret,
  getPaymentMethods,
  updateDefaultPaymentMethod,
  subscribe,
  removePaymentMethod,
} from '@/shared/api/Subscription.js';

export default {
  name: 'AppStripeElement',

  components: {
    AppBtn,
    AppInputCheckbox,
    SuccessModal,
  },

  props: ['subscription_id', 'mode'],

  watch: {
    name(val) {
      if (val) {
        this.errorMessages.name = false;
      }
    }
  },

  data() {
    return {
      loading: false,

      name: '',
      checked: true,

      intentToken: null,
      stripeAPIToken: process.env.VUE_APP_STRIPE_API_TOKEN,
      stripe: '',
      elements: '',
      card: '',
      addPaymentStatus: 0,
      addPaymentStatusError: '',

      paymentMethods: [],
      currentPaymentMethod: null,

      errorMessages: {
        name: false,
        card: false,
      },

      successModalShow: false,
      successModalText: '',
    };
  },

  methods: {
    async fetchPaymentMethods() {
      const { data } = await getPaymentMethods();

      return data.data;
    },

    async setDefaultPaymentMethod(id) {
      await updateDefaultPaymentMethod(id);
    },

    async setupCard() {
      this.addPaymentStatus = 1;

      const result = await this.stripe.confirmCardSetup(
        this.intentToken.client_secret,
        {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.name,
            },
          },
        }
      );

      if (result.error) {
        this.addPaymentStatus = 3;
        this.addPaymentStatusError = result.error.message;
        return;
      }

      this.paymentMethods = await this.fetchPaymentMethods();

      await this.setDefaultPaymentMethod(this.paymentMethods[0].id);

      this.addPaymentStatus = 2;
      this.card.clear();
      this.name = '';

      return this.paymentMethods[0];
    },

    async onPayClick() {
      if (!this.name) {
        this.errorMessages.name = true;
        return;
      }

      this.errorMessages = {
        name: false,
        card: false,
      };

      this.loading = true;

      const card = await this.setupCard();

      if (!card) {
        this.errorMessages.card = true;
        this.loading = false;
        return;
      }

      await this.subscribeToSubscription();

      if (this.isEdit) {
        await this.deleteCard({ except: card });
      }

      this.loading = false;

      this.$store.dispatch('auth/refreshSelf');

      this.successModalText = this.isEdit ? 'Payment Method Updated!' : 'Subscription Success!';
      this.successModalShow = true;
    },

    onModalClose() {
      this.$router.push({ name: 'Subscriptions' });
    },

    async subscribeToSubscription() {
      await subscribe(this.subscription_id);
    },

    async deleteCard({ except }) {
      const toDeleteCard = this.paymentMethods.filter(c => c.id !== except.id)[0];

      await removePaymentMethod(toDeleteCard.id);
    },

    async loadIntent() {
      const { data } = await getStripeClientSecret();

      this.intentToken = data;
    },

    /*
        Includes Stripe.js dynamically
    */
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];

      object.src = '//' + URL;

      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    configureStripe() {
      this.stripe = Stripe(this.stripeAPIToken); // eslint-disable-line

      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', { hidePostalCode: true });

      this.card.mount('#card-element');
    },

    async setupStripe() {
      this.includeStripe(
        'js.stripe.com/v3/',
        function () {
          this.configureStripe();
        }.bind(this)
      );

      await this.loadIntent();
    },
  },

  computed: {
    buttonTitle() {
      return this.isEdit ? 'SAVE' : 'CONFIRM AND PAY';
    },

    isEdit() {
      return this.mode === 'edit';
    },
  },

  async mounted() {
    await this.setupStripe();
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  font-family: 'Inter';
  font-style: normal;

  margin: 12px 0px;

  label {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #282828;

    margin-bottom: 6px;
  }

}

.StripeElement {
  height: 48px;
  padding: 12px 16px;

  border: 0.5px solid #777777;
  border-radius: 2px;
}

.confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px 32px;
  background: #f38725;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 1px 3px rgba(48, 49, 51, 0.1);
  border-radius: 2px;
  color: #fff;

  margin-top: 20px;

  &:hover {
    background: rgb(201, 113, 31);
    color: #fff !important;
  }
}
</style>